import { prefix } from './uri'

// Provide nuxt-axios instance to use same configuration across the whole project
// I've used typical CRUD method names and actions here
export default $axios => (resource, supportedMethods, parentResource) => {
  const result = {}
  let attachMethods = []
  // prepare CRUD methods
  if (supportedMethods.length > 0) {
    // const nestedResources = supportedMethods.filter(m => typeof m === 'object')
    supportedMethods
      .filter(m => typeof m !== 'object')
      .forEach((methodName) => {
        switch (methodName) {
          case '*':
            attachMethods.push('index')
            attachMethods.push('show')
            attachMethods.push('create')
            attachMethods.push('edit')
            attachMethods.push('store')
            attachMethods.push('update')
            attachMethods.push('delete')
            break
          case 'index':
            attachMethods.push('index')
            break
          case 'show':
            attachMethods.push('show')
            break
          case 'create':
            attachMethods.push('create')
            break
          case 'edit':
            attachMethods.push('edit')
            break
          case 'store':
            attachMethods.push('store')
            break
          case 'update':
            attachMethods.push('update')
            break
          case 'delete':
            attachMethods.push('delete')
            break
          case 'filterData':
            attachMethods.push('filterData')
            break
          case 'search':
            attachMethods.push('search')
            break
        }
      })
  }
  // leave only unique
  attachMethods = attachMethods.filter((v, i, s) => s.indexOf(v) === i)
  const resourceUrlName = ((parentResource) => {
    let url = resource.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
    parentResource = parentResource && parentResource.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
    url = url.replace(`${parentResource}.`, '')
    return url
  })(parentResource)
  // set parent url
  const getParentUrl = (parentResource, parentId) => {
    let url = ''
    if (parentResource && parentId) {
      url = `/${parentResource}/${parentId}`
    } else if (parentResource) {
      url = `/${parentResource}`
    }
    return url.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
  }
  // get query string
  const getQueryString = (query) => {
    const _query = []
    if (query && Object.keys(query).length) {
      for (const argKey of Object.keys(query)) {
        _query.push(`${argKey}=${query[argKey]}`)
      }
    }
    return _query.length ? `?${_query.join('&')}` : ''
  }
  // attach methods
  for (const methodName of attachMethods) {
    switch (methodName) {
      case 'index':
        result[methodName] = ({ parentId, query }) => {
          const url = `${prefix}${getParentUrl(parentResource, parentId)}/${resourceUrlName}${getQueryString(query)}`
          return $axios.$get(url)
        }
        break
      case 'show':
        result[methodName] = ({ id, parentId, query }) => {
          const url = `${prefix}${getParentUrl(parentResource, parentId)}/${resourceUrlName}/${id}${getQueryString(query)}`
          return $axios.$get(url)
        }
        break
      case 'create':
        result[methodName] = ({ parentId, payload }) => {
          const url = `${prefix}${getParentUrl(parentResource, parentId)}/${resourceUrlName}/create`
          return $axios.$get(url, payload)
        }
        break
      case 'edit':
        result[methodName] = ({ id, parentId, payload }) => {
          const url = `${prefix}${getParentUrl(parentResource, parentId)}/${resourceUrlName}/${id}/edit`
          return $axios.$get(url, payload)
        }
        break
      case 'store':
        result[methodName] = ({ parentId, query, payload }) => {
          const url = `${prefix}${getParentUrl(parentResource, parentId)}/${resourceUrlName}${getQueryString(query)}`
          return $axios.$post(url, payload)
        }
        break
      case 'update':
        result[methodName] = ({ id, parentId, query, payload }) => {
          const url = `${prefix}${getParentUrl(parentResource, parentId)}/${resourceUrlName}/${id}${getQueryString(query)}`
          return $axios.$put(url, payload)
        }
        break
      case 'delete':
        result[methodName] = ({ id, parentId, query }) => {
          const url = `${prefix}${getParentUrl(parentResource, parentId)}/${resourceUrlName}/${id}${getQueryString(query)}`
          return $axios.$delete(url)
        }
        break
      case 'filterData':
        result[methodName] = ({ parentId, query }) => {
          const url = `${prefix}${getParentUrl(parentResource, parentId)}/${resourceUrlName}/filter-data${getQueryString(query)}`
          return $axios.$get(url)
        }
        break
      case 'search':
        result[methodName] = ({ parentId, phrase, query }) => {
          const url = `${prefix}${getParentUrl(parentResource, parentId)}/${resourceUrlName}/search/${phrase || ''}${getQueryString(query)}`
          return $axios.$get(url)
        }
        break
    }
  }
  return result
}
