import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _43191270 = () => interopDefault(import('~/pages/affiliates.vue' /* webpackChunkName: "" */))
const _2f1646a1 = () => interopDefault(import('~/components/forms/AffiliateEditForm.vue' /* webpackChunkName: "pages/affiliates/_id" */))
const _920ff1e6 = () => interopDefault(import('~/pages/talks.vue' /* webpackChunkName: "" */))
const _6744d9a6 = () => interopDefault(import('~/components/forms/TalkPreviewForm.vue' /* webpackChunkName: "pages/talks/_id" */))
const _4ededbd8 = () => interopDefault(import('~/pages/tec.vue' /* webpackChunkName: "" */))
const _22139184 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _5bfd5530 = () => interopDefault(import('../pages/affiliates.vue' /* webpackChunkName: "pages/affiliates" */))
const _5ad7c0c4 = () => interopDefault(import('../pages/customers/index.vue' /* webpackChunkName: "pages/customers/index" */))
const _cada89c8 = () => interopDefault(import('../pages/debug/index.vue' /* webpackChunkName: "pages/debug/index" */))
const _74bfeaf2 = () => interopDefault(import('../pages/faqs.vue' /* webpackChunkName: "pages/faqs" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _79e78b36 = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _d5f21fa2 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _3543fae6 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _10b5e766 = () => interopDefault(import('../pages/talks.vue' /* webpackChunkName: "pages/talks" */))
const _69f0ab14 = () => interopDefault(import('../pages/tasks.vue' /* webpackChunkName: "pages/tasks" */))
const _f4e79dd0 = () => interopDefault(import('../pages/tec.vue' /* webpackChunkName: "pages/tec" */))
const _7364f08e = () => interopDefault(import('../pages/users.vue' /* webpackChunkName: "pages/users" */))
const _057fd38a = () => interopDefault(import('../pages/notes/private.vue' /* webpackChunkName: "pages/notes/private" */))
const _b13e463e = () => interopDefault(import('../pages/notes/public.vue' /* webpackChunkName: "pages/notes/public" */))
const _4baa7b83 = () => interopDefault(import('../pages/reports/cities.vue' /* webpackChunkName: "pages/reports/cities" */))
const _9c2d8dda = () => interopDefault(import('../pages/reports/hostess.vue' /* webpackChunkName: "pages/reports/hostess" */))
const _fb4ea8fc = () => interopDefault(import('../pages/reports/hostess-chat.vue' /* webpackChunkName: "pages/reports/hostess-chat" */))
const _4d043ce7 = () => interopDefault(import('../pages/reports/payments.vue' /* webpackChunkName: "pages/reports/payments" */))
const _5bcb563e = () => interopDefault(import('../pages/reports/users.vue' /* webpackChunkName: "pages/reports/users" */))
const _63b69e59 = () => interopDefault(import('../pages/tools/anti-scam.vue' /* webpackChunkName: "pages/tools/anti-scam" */))
const _257d1cf4 = () => interopDefault(import('../pages/customers/_id.vue' /* webpackChunkName: "pages/customers/_id" */))
const _4ae656f8 = () => interopDefault(import('../pages/debug/_id.vue' /* webpackChunkName: "pages/debug/_id" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/affiliates/:id",
    components: {
      default: _43191270,
      modal: _2f1646a1
    },
    name: "affiliates-id"
  }, {
    path: "/talks/:id",
    components: {
      default: _920ff1e6,
      modal: _6744d9a6
    },
    name: "talks-id"
  }, {
    path: "/tec/:id",
    components: {
      default: _4ededbd8,
      modal: _4ededbd8
    },
    name: "tec-id"
  }, {
    path: "/404",
    component: _22139184,
    name: "404"
  }, {
    path: "/affiliates",
    component: _5bfd5530,
    name: "affiliates"
  }, {
    path: "/customers",
    component: _5ad7c0c4,
    name: "customers"
  }, {
    path: "/debug",
    component: _cada89c8,
    name: "debug"
  }, {
    path: "/faqs",
    component: _74bfeaf2,
    name: "faqs"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/messages",
    component: _79e78b36,
    name: "messages"
  }, {
    path: "/profile",
    component: _d5f21fa2,
    name: "profile"
  }, {
    path: "/settings",
    component: _3543fae6,
    name: "settings"
  }, {
    path: "/talks",
    component: _10b5e766,
    name: "talks"
  }, {
    path: "/tasks",
    component: _69f0ab14,
    name: "tasks"
  }, {
    path: "/tec",
    component: _f4e79dd0,
    name: "tec"
  }, {
    path: "/users",
    component: _7364f08e,
    name: "users"
  }, {
    path: "/notes/private",
    component: _057fd38a,
    name: "notes-private"
  }, {
    path: "/notes/public",
    component: _b13e463e,
    name: "notes-public"
  }, {
    path: "/reports/cities",
    component: _4baa7b83,
    name: "reports-cities"
  }, {
    path: "/reports/hostess",
    component: _9c2d8dda,
    name: "reports-hostess"
  }, {
    path: "/reports/hostess-chat",
    component: _fb4ea8fc,
    name: "reports-hostess-chat"
  }, {
    path: "/reports/payments",
    component: _4d043ce7,
    name: "reports-payments"
  }, {
    path: "/reports/users",
    component: _5bcb563e,
    name: "reports-users"
  }, {
    path: "/tools/anti-scam",
    component: _63b69e59,
    name: "tools-anti-scam"
  }, {
    path: "/customers/:id",
    component: _257d1cf4,
    name: "customers-id"
  }, {
    path: "/debug/:id",
    component: _4ae656f8,
    name: "debug-id"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
